/* ------------------------------ *
 * ion-content
 * ------------------------------ */
ion-content {
  --background: none;
  background: none;
}

/* ------------------------------ *
 * ion-title
 * ------------------------------ */
ion-title.ion-title-larger {
  font-size: 1.5rem;
}

/* ------------------------------ *
 * ion-item
 * ------------------------------ */
ion-item.no-background {
  --background: none;
  background: none;
}
ion-item.override {
  --border-radius: 8px;
  --border-style: solid;
  --border-color: rgba(255, 255, 255, 0.1);
  --background: rgba(255, 255, 255, 0.05);
}
 ion-item.input-item {
  --box-shadow: none!important;
  --background: linear-gradient(to right, #100700, #402c13)!important;
  --border-color: #261b13!important;
  --border-radius: 0!important;
  --border-style: solid!important;
  --border-width: 2px!important;
  
}

/* ------------------------------ *
 * ion-input
 * ------------------------------ */
ion-input.override {
  --highlight-color-focused: var(--ion-color-tertiary);
  --highlight-color-invalid: var(--ion-color-tertiary);
  --highlight-color-validvar: var(--ion-color-tertiary);
}
ion-input.placeholder-font-patch input {
  font-family: "Bahnschrift" !important;
  font-size: 0.8rem !important;
  font-weight: 300;
}
ion-input.placeholder-font-patch > label > div.label-text-wrapper > div {
  font-family: "Bahnschrift" !important;
  font-size: 0.9rem !important;
}

ion-input.placeholder-font-patch.amount-input input {
  font-family: "Bahnschrift" !important;
  font-size: 0.8rem !important;
  font-weight: 300;
}

ion-input.placeholder-font-patch.advmembership-input input {
  font-family: "Bahnschrift" !important;
  font-size: 0.8rem !important;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*--------------------------------*
* ion-label
* --------------------------------*/
.label-external {
  font-family: "Bahnschrift";
  font-size: 13px;
  color: white;
  padding-left: 5px;
  font-weight: 100;
}
/* ------------------------------ *
 * ion-button
 * ------------------------------ */

ion-button,
ion-button[size="default"] {
  border-radius: 0.5rem;
  --border-radius: 0.5rem;
  ion-icon {
    font-size: 1.2rem;
  }
  ion-label {
    font-size: 0.8rem;
    text-transform: none;
  }
}

ion-button[size="small"] {
  border-radius: 0.5rem;
  ion-icon {
    font-size: 1.2 rem;
  }
  ion-label {
    font-size: 0.8rem;
    text-transform: none;
  }
}

ion-button[size="large"] {
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 20px;
  --padding-end: 20px;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: unset;
  
  ion-icon {
    font-size: 1.8rem;
  }
  ion-label {
    font-size: 1.1rem;
    text-transform: unset;
  }
}
/* Custom buttons color */
ion-button.btn-dark-brown {
  --background: #1f1e1e;
  --color: #fff;
  --border-style: solid;
  --border-width: 1px;
  --border-color: #1f1e1e;
  --transition: all 0.3s;
}
ion-button.btn-dark-brown:hover {
  --background: #050505;
  --color: #b18e33;
  --border-style: solid;
  --border-width: 1px;
  --border-color: #b18e33;
}
ion-button{
  --background:linear-gradient(90deg, #e4c96f 25%, #b38117 50%, #d0ac52 75%, #f9f3bb 100%);
}
/* ------------------------------ *
 * ion-modal
 * ------------------------------ */
ion-modal.modal-max-width-375 {
  --max-width: 375px;
  --min-height: 650px;
  font-family: "Bahnschrift" !important;
}
text{
  fill: aliceblue;
}
ion-modal{
  --backdrop-opacity: 0.5;
  backdrop-filter: blur(3px);

  /*&::part(backdrop) {
      background: #000;
  }*/

  /*&::part(handle) {
      background: var(--ion-color-primary);
      width: 150px;
      height: 6px;
  }*/

  &::part(content) {
      border-radius: 20px; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}
/* -----------------------------------*
* scrollbar
* ------------------------------------*/
// Hide scrollbar for Chrome, Safari and Opera
ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
  }

// Hide scrollbar for IE, Edge and Firefox
ion-content::part(scroll) {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  }
  /* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*-----------------------------------
* Alert font
*-----------------------------------*/
  div.alert-message {
    font-family: 'Bahnschrift';
}

/*-----------------------------------
* Toast font
*-----------------------------------*/
div.loading-content {
  font-family: 'Bahnschrift';
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ios ion-title{
  height: fit-content;
}