/* Fonts */
@font-face {
  font-family: 'AcquireLight';
  src: url('./aquirelight/AquireLight.otf') format('opentype');
}

@font-face {
  font-family: 'AcquireBold';
  src: url('./aquirelight/AquireBold.otf') format('opentype');
}

@font-face {
  font-family: 'AcquireBold2';
  src: url('./aquirelight/AquireBold2.otf') format('opentype');
}

@font-face {
  font-family: 'Bahnschrift';
  src: url('./bahnschrift/BAHNSCHRIFT.TTF') format('truetype');
}

@font-face {
  font-family: 'Bahnschrift-Light';
  src: url('./bahnschrift/BAHNSCHRIFT 2.TTF') format('truetype');
}
