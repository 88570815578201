$base-uri: '../../fonts';
$extension: 'ttf';

$karla: 'karla';
$nunito: 'nunito';
$opensans: 'opensans';

$fonts: (
  'karla': $karla,
  'karla-italic': $karla,
  'nunito' : $nunito,
  'nunito-italic' : $nunito,
  'opensans' : $opensans,
  'opensans-italic' : $opensans,
);

@each $font-name, $font-family in $fonts {
  @font-face {
    font-family: $font-name;
    src: url('#{$base-uri}/#{$font-family}/#{$font-name}.#{$extension}');
  };
}
